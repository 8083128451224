import Head from "next/head";
import { GetStaticProps } from "next";

import LoginC from "@/components/auth/login";
import pageSeoData from "@/data/seo/page-data.json";
import SeoMetaData from "@/components/utils/seo-metadata";
import { PageSeoHeaderProps } from "@/@types/SeoHeader";

export default function Login({ seoData }: PageSeoHeaderProps) {
  return (
    <>
      <Head>
        <title>{seoData.title}</title>
        <SeoMetaData data={seoData} />
      </Head>

      <LoginC />
    </>
  );
}

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: { seoData: pageSeoData.LOGIN },
  };
};
