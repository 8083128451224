import { useEffect, useState } from "react";

import * as yup from "yup";
import Link from "next/link";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, Card, Col, Container, Form, Row, Spinner } from "reactstrap";
import Image from "next/image";
import { useRouter } from "next/router";

import classes from "@/styles/auth/login.module.css";
import { routes } from "@/utils/routes";
import InputGroup from "../common/input-group";
import useAlertBar from "@/hooks/useAlertBar";
import { loginService } from "@/services/authService";
import { refreshTokenKey, setRefreshToken } from "@/utils/token-manager";
import Hero from "../generic/hero";
import About from "../generic/about";
import { useIsMobile } from "@/hooks/useIsMobile";
import { dashboardUrl } from "@/utils/constants";
import { shipvistaLargeLogo } from "@/utils/images";
import BlogCarousel from "../generic/blog-carousel";

type Inputs = {
  email: string;
  password: string;
};

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email Address is required"),
  password: yup.string().required("Password is required"),
});

export default function Login() {
  const { openAlertBar } = useAlertBar();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(validationSchema),
    criteriaMode: "all",
    shouldFocusError: true,
  });

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    setLoading(true);
    try {
      const requestBody = {
        user_id: formData.email ? formData.email.toLowerCase() : formData.email,
        password: formData.password,
      };

      const { data } = await loginService(requestBody);

      if (data.status) {
        const { tokenString, expireAt } = data.access_token;

        window.localStorage.setItem("accessToken", tokenString);
        window.localStorage.setItem("expireAt", expireAt);

        let url = `${dashboardUrl}/login-success?accessToken=${tokenString}&expireAt=${expireAt}`;

        if (data.refresh_token) {
          const { tokenString: refreshString, expireAt: refreshExpireAt } =
            data.refresh_token;
          setRefreshToken(refreshTokenKey, refreshString, refreshExpireAt);

          url += `&refreshToken=${refreshString}&refreshExpireAt=${refreshExpireAt}`;
        }

        // redirect to dashboard
        window.location.href = url;
      } else {
        openAlertBar(data.message, false);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      openAlertBar(
        "Unexpected error occurred while trying to log you in. Please try again ",
        false
      );
      console.log(error);
    }
  };

  const redirectToHome = () => {
    router.push(routes.home);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <>
      <Container className="my-5">
        <Card className={`border-0 ${classes.mainCard} shadow`}>
          <Row>
            {useIsMobile() ? null : (
              <Col
                className={
                  classes.col1 + " d-flex align-items-center flex-wrap rounded"
                }
                xs={12}
                lg={7}
              >
                <div className="mx-auto">
                  <div className="text-center ">
                    <Image
                      src={shipvistaLargeLogo}
                      alt="Shipvista Logo"
                      className="img-fluid"
                      width={320}
                      height={100}
                    />
                  </div>
                  <div className="mb-5">
                    <BlogCarousel />
                  </div>
                </div>
              </Col>
            )}
            <Col xs={12} lg={5}>
              <div className="mx-auto p-3">
                <Form
                  onSubmit={handleSubmit(onSubmit)}
                  className="my-4 needs-validation"
                >
                  <h2 className="text-center mb-4">Login</h2>
                  <InputGroup
                    required
                    label="Email Address"
                    name="email"
                    register={register}
                    error={errors.email?.message}
                  />
                  <InputGroup
                    required
                    label="Password"
                    type="password"
                    name="password"
                    register={register}
                    error={errors.password?.message}
                  />
                  <Row>
                    <Col sm={12}>
                      <Link href={routes.forgotPassword}>Forgot Password?</Link>
                    </Col>
                    <Col className="text-end">
                      <div className="">
                        Don&#39;t have an account{" "}
                        <Link href={routes.register}>Sign Up Now?</Link>
                      </div>
                    </Col>
                  </Row>

                  <Button
                    type="submit"
                    disabled={loading}
                    className={`w-100 mt-2 ${classes.submitBtn}`}
                  >
                    LOGIN
                    {loading && <Spinner size="sm" className="ms-3" />}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Card>
      </Container>
      <div className={classes.overLay} onClick={redirectToHome}></div>
      <div className={classes.container} onClick={redirectToHome}>
        <About />
      </div>
    </>
  );
}
